import Vue from 'vue'

import {
  Tab,
  Tabs,
  Field,
  Button,
  Overlay,
  Toast,
  Loading,
  Popup,
  Picker,
  Slider,
  Icon,
  List
} from 'vant'

import request from '@/utils/service'
import router from './router'
import store from './store'
import * as filters from './filters'
import App from './App'

import 'vant/lib/index.css'

Toast.setDefaultOptions({ duration: 3000 })

Vue.prototype.$store = store
Vue.prototype.$toast = Toast
Vue.prototype.$request = request

Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Field)
Vue.use(Button)
Vue.use(Overlay)
Vue.use(Loading)
Vue.use(Popup)
Vue.use(Picker)
Vue.use(Slider)
Vue.use(Icon)
Vue.use(List)
Vue.use(router)

Object.keys(filters).forEach((key) => Vue.filter(key, filters[key]))

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
