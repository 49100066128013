<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',

  data() {
    return {
      transitionName: '',
      videoMain: true,
      loaded: false,
      hideLoading: false
    }
  },
}
</script>

<style lang="scss">
@import 'styles/index.scss';

body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.router-view-c {
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s;
}

.forward-leave-active {
  transform: translateX(-30%);
}
.forward-enter {
  transform: translateX(100%);
}
.back-leave-active {
  transform: translateX(100%);
}
.back-enter {
  transform: translateX(-30%);
}
.forward-enter-active,
.back-leave-active {
  z-index: 10;
}

.full-page {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
}
</style>
