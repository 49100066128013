function filterImage(url, side) {
  const isHttpUrl = url && url.indexOf('http') === -1 && url.indexOf('/') !== 0

  if (!isHttpUrl) return url

  return process.env.OSS_URL + url + (side ? `?x-oss-process=image/resize,m_fill,w_${side}` : '')
}

function filterPrice(val) {
  if (val % 100 === 0) return parseInt(val / 100, 10)

  return (val / 100).toFixed(val % 10 === 0 ? 1 : 2)
}

export { filterImage, filterPrice }
