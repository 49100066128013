import axios from 'axios'
import { Toast } from 'vant'

const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 20000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'content-type': 'application/json',
  },
  method: 'post'
})

axios.defaults.withCredentials = true

/* eslint no-param-reassign: ["error", { "props": false }] */
service.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    const webPortal_id = localStorage.getItem('webPortal_id')
    if (token) config.headers.Authorization = `Bearer ${token}`
    if (webPortal_id) config.headers.channel = `webPortal-${webPortal_id}`

    if (!(config.data instanceof FormData)) {
      config.data = JSON.parse(JSON.stringify(config.data) || '{}')
    }

    if (config.url.startsWith('/util')) config.baseURL = config.baseURL.slice(0, -8)

    if (config.data.current) {
      config.data.pageNo = config.data.current
      delete config.data.current
    }

    if (config.data.limit) {
      config.data.pageSize = config.data.limit
      delete config.data.limit
    }

    return config
  },
  (error) => Promise.reject(error)
)

service.interceptors.response.use(
  (res) => res.data,
  (error) => {
    switch (error.response.status) {
      case 400:
        Toast(error.response.data.message)
        break

      case 401:
        localStorage.removeItem('token')
        Toast.fail('token error')
        window.location.reload()
        break

      default:
        Toast.clear()
        break
    }

    return Promise.reject(error.response)
  }
)

export default service
