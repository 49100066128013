import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const ISMOBILE = () => {
  const flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  return flag
}
const pathArr = window.location.pathname.split('/')

let basePath = ''
if(pathArr.length > 1) {
  basePath = pathArr[1]
}
export default new Router({
  mode: 'history',
  base: basePath ? `/${basePath}/` : '',
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('@/pages/index/index')
    }
  ]
})
